import {Navigate} from 'react-router-dom';
import {userAuth} from 'contexts/AuthContext';

const ProtectedRoute = ({children}) => {
  const {user} = userAuth();
  if (!user) {
    return <Navigate to='/auth/signin' />;
  }

  if (user ) {
    return children;
  }
};

export default ProtectedRoute;
