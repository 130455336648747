import {createContext, useContext, useEffect, useState} from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import {auth, db} from '../firebaseDb';
import {doc, onSnapshot} from 'firebase/firestore';


const UserContext = createContext();

export const AuthContextProvider = ({children}) => {
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logOut = () => {
    setUser({});
    setUserData({});
    return signOut(auth);
  };
  const getUserData = (currentUserEmail) => {
    const docRef = doc(db, 'Users', currentUserEmail);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setUserData({...docSnapshot.data(), key: docSnapshot.id});
      }
    });
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        getUserData(currentUser.email);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{user, userData, logOut, signIn, getUserData}}>
      {children}
    </UserContext.Provider>
  );
};
export const userAuth = () => {
  return useContext(UserContext);
};
