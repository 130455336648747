

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import {AuthContextProvider} from 'contexts/AuthContext';
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import PrivateRoute from 'layouts/ProtectedRoute';
ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <Routes>
      <Route path={'/auth/signin'} Component={AuthLayout} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          </PrivateRoute>
        }
              />
      </Routes>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
