
// Chakra imports
import {
	Flex,
	Grid,
	Icon,
	Spacer,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Td,
	Button,
	Stack,
	Modal,
  	ModalOverlay,
  	ModalContent,
  	ModalHeader,
  	ModalFooter,
  	ModalBody,
  	ModalCloseButton,
	useDisclosure,
	Input,
	Link
} from '@chakra-ui/react';
// Styles for the circular progressbar
import medusa from 'assets/img/cardimgfree.png';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
// Icons
import { BsArrowRight, BsPencilFill, BsFile, BsTrash } from 'react-icons/bs';

import {useEffect, useState} from 'react';

import {db} from '../../firebaseDb';
import { userAuth } from 'contexts/AuthContext';
import {collection, onSnapshot, addDoc, serverTimestamp, setDoc, doc} from 'firebase/firestore';

export default function Dashboard() {
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState('');
	const {userData} = userAuth()
	const { isOpen, onOpen, onClose } = useDisclosure()

	useEffect(()=>{
		// Fetch Message Info
		const mRef = collection(db, 'Messages');
		const unsubscribe = onSnapshot(mRef, (querySnapshot) => {
		  const tempMessages = [];
		  querySnapshot.forEach((docu) => {
			if (!docu.data().archive) {
				tempMessages.push({
					...docu.data(), key: docu.id,
				  });
			}
		  });
		  setMessages(tempMessages);
		  return () => {
			unsubscribe();
		  };
		});
	  }, []);

	  const handleSaveMessage = () => {
		addDoc(collection(db, "Messages"), {
			name: userData.name,
			message: message,
			time: serverTimestamp()
		  }).then(()=>{
			setMessage('')
			onClose();
		  });
	  }
	  const handleDelRow = (row) => {
		console.log(row.key)
		setDoc(doc(db, "Messages", row.key), {
			archive: true,
		  }, {merge: true}).then(()=>{
			console.log('message deleted')
		  });
	  }
	return (
		<Flex flexDirection='column' pt={{ base: '120px', md: '75px' }}>
			 <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Class Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
			<Input variant='text' outline={'solid'} value={message} placeholder='Write a message...' onChange={(e) => setMessage(e.target.value)}></Input>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme='green' onClick={handleSaveMessage}>Save Message</Button>
          </ModalFooter>
        </ModalContent>
      			</Modal>
			<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', '2xl': '2fr 1.2fr 1.5fr' }} my='26px' gap='18px'>
				{/* Welcome Card */}
				<Card
					p='0px'
					gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
					bgImage={medusa}
					bgSize='cover'
					bgPosition={{ sm: '60%', '2xl': 'auto' }}
					pb={{ sm: '500px', md: '50px' }}>
					<CardBody w='100%' h='100%'>
						<Flex flexDirection={{ sm: 'column', lg: 'row' }} w='100%' h='100%'>
							<Flex flexDirection='column' h='100%' p='22px' minW='60%' lineHeight='1.6'>
								<Text fontSize='sm' color='gray.400' fontWeight='bold'>
									Hello,
								</Text>
								<Text fontSize='28px' color='#fff' fontWeight='bold' mb='18px'>
									{userData.name}
								</Text>
								<Text fontSize='md' color='gray.400' fontWeight='normal' mb='auto'>
									This is a happy quokka <Icon
											as={BsArrowRight}
											w='20px'
											h='20px'
											color='#fff'
											fontSize='2xl'
											transition='all .3s ease'
											mx='.3rem'
											cursor='pointer'
											pt='4px'
											_hover={{ transform: 'translateX(20%)' }}
										/><br />
									Ask me anything.
								</Text>
								<Spacer />
							</Flex>
						</Flex>
					</CardBody>
				</Card>
			</Grid>
			<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '2fr 1fr' }} gap='24px'>
				{/* Projects */}
				<Card p='16px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
					<CardHeader p='12px 0px 28px 0px'>
						<Stack direction='row' spacing={5}>
							<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
								Class Messages
							</Text>
							<Button
							onClick={onOpen}
								borderRadius='12px'
								bg='brand.200'
								_hover={{ opacity: '0.8' }}
								_active={{ opacity: '0.9' }}
								me={{ base: 'none', lg: '20px' }}
								leftIcon={<Icon color='white' as={BsPencilFill} me='6px' />}>
								<Text fontSize='xs' color='#fff' fontWeight='bold'>
									Write a message!
								</Text>
							</Button>
						</Stack>
					</CardHeader>
					<Table variant='simple' color='#fff'>
						<Thead>
							<Tr my='.8rem' ps='0px'>
								<Th
									ps='0px'
									color='gray.400'
									fontFamily='Plus Jakarta Display'
									borderBottomColor='#56577A'>
									Student
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									Message
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{messages.map((row, index, arr) => {
								return (
									<Tr><Td>{row.name}</Td> 
									<Td>{row.message}</Td>
									{userData.name === 'Teacher Mike' && <Td><Icon as={BsTrash} color='red.500' w='15px' h='15px' me='5px' onClick={() => handleDelRow(row)}/></Td>}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Card>
				{/* Orders Overview */}
				<Card>
					<CardHeader mb='32px'>
						<Flex direction='column'>
							<Text fontSize='lg' color='#fff' fontWeight='bold' mb='16px'>
								Exam Review
							</Text>
							<Stack direction={'column'} spacing={4}>
							<Flex align='center'>
								<Link href='https://crosswordlabs.com/view/i2c-exam-review'>
									<Stack direction={'row'}>
									<Icon as={BsFile} color='green.500' w='15px' h='15px' me='5px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									Exam crossword
								</Text>
								</Stack>
								</Link>
							</Flex>
							<Flex align='center'>
								<Link href='https://play.blooket.com/play?hwId=6540ce6c769b892d04124140'>
									<Stack direction={'row'}>
									<Icon as={BsFile} color='green.500' w='15px' h='15px' me='5px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									Blooket - Review
								</Text>
								</Stack>
								</Link>
							</Flex>
							<Flex align='center'>
								<Link href='https://scratch.mit.edu/projects/914429494'>
									<Stack direction={'row'}>
									<Icon as={BsFile} color='green.500' w='15px' h='15px' me='5px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									Scratch - Language Arts
								</Text>
								</Stack>
								</Link>
							</Flex>
							</Stack>
						</Flex>
					</CardHeader>
					<CardBody>
					</CardBody>
				</Card>
			</Grid>
		</Flex>
	);
}
